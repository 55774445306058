<template>
  <!-- Preloader -->
  <div class="preloader">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- Preloader End -->

  <!-- Document Wrapper   
  =============================== -->
  <div id="main-wrapper">
    <!-- Header -->
    <Header />
    <!-- Header End -->

    <!-- Content
    ============================================= -->
    <div id="content" role="main">
      <!-- Intro
      ============================================= -->
      <Home :imageUrl="imageUrl" />
      <!-- Intro end -->

      <!-- About
      ============================================= -->
      <About />
      <!-- About end -->

      <!-- Services
      ============================================= -->
      <Services />
      <!-- Services end -->

      <!-- Some of my work
      ============================================= -->
      <Portfolio />
      <!-- Some of my work end -->

      <!-- Call to Action
      ============================================= -->
      <Callout
        message="Interested in working together?"
        callout="Let's get connected"
      />
      <!-- Call to Action end -->

      <!-- Resume
      ============================================= -->
      <Resume />
      <!-- Resume end -->

      <!-- Contact Me
      ============================================= -->
      <Contact />
      <!-- Contact Me end -->
    </div>
    <!-- Content end -->

    <!-- Footer
    ============================================= -->
    <Footer />
    <!-- Footer end -->
  </div>
  <!-- Document Wrapper end -->
</template>

<script>
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
// Shared components
import Callout from "./components/shared/Callout";
// Service
import ImageService from "./services/image.service";

export default {
  name: "Darryn-Jacobs",
  components: {
    Header,
    Home,
    About,
    Services,
    Portfolio,
    Resume,
    Contact,
    Footer,
    Callout,
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  async beforeMount() {
    await this.headerImageUrl();
  },
  methods: {
    async headerImageUrl() {
      const service = new ImageService(
        "1Tjo_eJ780aMru-x_yX6BB3-r-piT8VoyAmOEW1uBcQ"
      );

      return await service.getRandomHeaderImage().then((res) => {
        this.imageUrl = res;
      });
    },
  },
};
</script>

<style></style>
