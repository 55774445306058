<template>
  <section>
    <div class="hero-wrap">
      <div class="hero-mask opacity-8 bg-dark"></div>
      <div
        class="hero-bg parallax"
        :style="{ 'background-image': 'url(' + imageUrl + ')' }"
      ></div>
      <div class="hero-content section">
        <div class="container text-center py-5 wow fadeInUp">
          <h2 class="text-10 font-weight-600 text-white mb-5">
            {{ message }}
          </h2>
          <a
            href="#contact"
            class="btn btn-primary rounded-0 smooth-scroll wow rubberBand"
            data-wow-delay="1s"
            >{{ callout }}</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ImageService from "../../services/image.service";

export default defineComponent({
  name: "Callout",
  props: {
    message: String,
    callout: String,
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  async beforeMount() {
    await this.calloutImageUrl();
  },
  methods: {
    async calloutImageUrl() {
      const service = new ImageService(
        "1Tjo_eJ780aMru-x_yX6BB3-r-piT8VoyAmOEW1uBcQ"
      );

      return await service.getRandomCalloutImage().then((res) => {
        this.imageUrl = res;
      });
    },
  },
});
</script>
