<template>
  <section id="contact" class="section bg-primary">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 text-center text-lg-left wow fadeInUp">
          <h2 class="text-10 font-weight-600 mb-5">Let's connect</h2>
          <p class="text-5 mb-5">
            I would love to collaborate on something cool, or even just talk
            tech. Let's connect and create something together.
          </p>
          <h3 class="text-5 font-weight-600">Based in:</h3>
          <address class="text-4">
            <i class="fas fa-map-pin"></i>&nbsp; Cape Town, South Africa.
          </address>
          <ul
            class="social-icons social-icons-lg justify-content-center justify-content-lg-start ml-lg-n2 mt-5"
          >
            <li class="social-icons-medium">
              <a
                data-toggle="tooltip"
                href="https://darryn-jacobs.medium.com/"
                target="_blank"
                title=""
                data-original-title="Medium"
                ><i class="fab fa-medium"></i
              ></a>
            </li>
            <li class="social-icons-linkedin">
              <a
                data-toggle="tooltip"
                href="https://www.linkedin.com/in/darryn-jacobs-96759472/"
                target="_blank"
                title=""
                data-original-title="Linkedin"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
            <li class="social-icons-github">
              <a
                data-toggle="tooltip"
                href="https://github.com/DarrynJ/"
                target="_blank"
                title=""
                data-original-title="GitHub"
                ><i class="fab fa-github"></i
              ></a>
            </li>
          </ul>
        </div>
        <div
          class="col-lg-6 ml-auto mt-5 mt-lg-0 wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <h2 class="text-10 font-weight-600 text-center text-lg-left mb-5">
            Let's start the conversation
          </h2>
          <!-- Contact Form -->
          <form
            id="contact-form"
            class="form-border"
            action="#"
            method="post"
          >
            <div class="form-group mb-4">
              <label for="name">What's your name?</label>
              <input
                id="name"
                name="name"
                type="text"
                class="form-control py-1"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label for="email">Your email?</label>
              <input
                id="email"
                name="email"
                type="email"
                class="form-control py-1"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label for="form-message">What should we connect about?</label>
              <textarea
                id="form-message"
                name="form-message"
                class="form-control py-1"
                rows="4"
                required
              ></textarea>
            </div>
            <p class="pt-2 mb-0 text-center text-lg-left">
              <button
                id="submit-btn"
                class="btn btn-dark rounded-0 d-inline-flex"
                type="submit"
              >
                Send
                <span class="ml-3"><i class="fas fa-arrow-right"></i></span>
              </button>
            </p>
          </form>
          <!-- Contact Form end -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
};
</script>
