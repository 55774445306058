<template>
  <section id="services" class="section bg-light">
    <div class="container">
      <!-- Heading -->
      <p class=" text-center mb-2 wow fadeInUp">
        <span class="bg-primary text-dark px-2">What I Do?</span>
      </p>
      <h2 class="text-10 font-weight-600 text-center mb-5 wow fadeInUp">
        What I've dipped my toes in
      </h2>
      <!-- Heading end-->

      <div class="row">
        <div class="col-sm-6 col-lg-4 mt-5 wow fadeInUp">
          <div class="featured-box text-center px-md-4">
            <div class="featured-box-icon text-primary text-13">
              <i class="fas fa-hashtag"></i>
            </div>
            <h3 class="text-6 font-weight-600 mb-3">C#</h3>
            <p class="text-muted mb-0">
              Most of my journey consists of building applications using C# as
              my primary language choice. I've built everything from console
              applications running <code>Hello World</code>, to working on
              fullstack applications using the MVC framework to REST APIs.
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 mt-5 wow fadeInUp">
          <div class="featured-box text-center px-md-4">
            <div class="featured-box-icon text-primary text-13">
              <i class="fab fa-angular"></i>
            </div>
            <h3 class="text-6 font-weight-600 mb-3">Angular</h3>
            <p class="text-muted mb-0">
              I would say I'm relatively new to Angular, but I've picked up
              quite quickly and often it has become my go-to framework for web
              applications.
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 mt-5 wow fadeInUp">
          <div class="featured-box text-center px-md-4">
            <div class="featured-box-icon text-primary text-13">
              <i class="fab fa-node-js"></i>
            </div>
            <h3 class="text-6 font-weight-600 mb-3">NodeJs</h3>
            <p class="text-muted mb-0">
              I've only recently given more time to NodeJs and creating
              JavaScript server projects, and have absolutely enjoyed the
              learning process. I enjoy the fact that you make use of one
              language for building both the back and front ends of your
              application.
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 mt-5 wow fadeInUp">
          <div class="featured-box text-center px-md-4">
            <div class="featured-box-icon text-primary text-13">
              <i class="fab fa-python"></i>
            </div>
            <h3 class="text-6 font-weight-600 mb-3">Python</h3>
            <p class="text-muted mb-0">
              I would say Python is a language I've not given as much attention
              to as I would like to. I've created small scripts and followed
              some Machine Learning courses, but I definitely wish to spend some
              more time exploring this language.
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 mt-5 wow fadeInUp">
          <div class="featured-box text-center px-md-4">
            <div class="featured-box-icon text-primary text-13">
              <i class="fas fa-mobile-alt"></i>
            </div>
            <h3 class="text-6 font-weight-600 mb-3">Flutter</h3>
            <p class="text-muted mb-0">
              Flutter is my go-to for cross-platform mobile development. It's
              easy to pick up and the documentation is vast, making this a must
              consider for cross-platform development.
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 mt-5 wow fadeInUp">
          <div class="featured-box text-center px-md-4">
            <div class="featured-box-icon text-primary text-13">
              <i class="fab fa-vuejs"></i>
            </div>
            <h3 class="text-6 font-weight-600 mb-3">Vue</h3>
            <p class="text-muted mb-0">
              Vue caught my attention for how quickly it picked up a following
              and how web applications seem to run on this framework. I've
              enjoyed this framework so much that I even created this very
              website using it.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section></template
>

<script>
export default {
  name: "Services",
};
</script>
