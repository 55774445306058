<template>
  <section id="resume" class="section">
    <div class="container">
      <!-- Heading -->
      <p class=" text-center mb-2 wow fadeInUp">
        <span class="bg-primary text-dark px-2">Resume</span>
      </p>
      <h2 class="text-10 font-weight-600 text-center mb-5 wow fadeInUp">
        A summary of my journey
      </h2>
      <!-- Heading end-->

      <div class="row">
        <!-- My Learning Journey -->
        <div class="col-lg-6 wow fadeInUp">
          <h2 class="text-7 font-weight-600 mb-4 pb-2">
            My Learning Journey
          </h2>
          <div class="border-left border-2 border-primary pl-3">
            <h3 class="text-5">Infromation Technology Diploma</h3>
            <p class="mb-2">UNISA / 2022</p>
            <p class="text-muted">
              For 2022, my goal was to complete my studies and achieve the accreditation
              required to graduate in Information Technology. I was required to complete 6 modules,
              over the course of the year, to reach this goal.
            </p>
            <hr class="my-4" />
            <h3 class="text-5">Azure Developer Associate</h3>
            <p class="mb-2">Microsoft / 2020</p>
            <p class="text-muted">
              In 2020, I embarked on the journey of obtaining Microsoft
              certification. This gave me a good in-depth insight into
              Microsoft's Azure platform and everything it has to offer. In the
              end I otained my Azure Developer Associate certification after
              completing the AZ-203 examination.
            </p>
            <hr class="my-4" />
            <h3 class="text-5">TypeScript Masterclass</h3>
            <p class="mb-2">Udemy / 2019</p>
            <p class="text-muted">
              In modern web development it is advised to have a good JavaScript
              insight. TypeScript is like JavaScript on steriods. This course
              was such a good learning experience and today I tend to lean
              towards TypeScript as my everyday language of choice.
            </p>
            <hr class="my-4" />
            <h3 class="text-5">Angular Core Deep Dive</h3>
            <p class="mb-2">Udemy / 2019</p>
            <p class="text-muted">
              Front-end web application frameworks always caught my attention,
              so naturally Angular stood out. After learning TypeScript, Angular
              was the next step. I do tend to choose Angular as my framework of
              choice for web development, but Vue has been picking up some pace.
            </p>
          </div>
        </div>
        <!-- My Developer Jourey -->
        <div class="col-lg-6 mt-5 mt-lg-0 wow fadeInUp" data-wow-delay="0.2s">
          <h2 class="text-7 font-weight-600 mb-4 pb-2">
            My Developer Jourey
          </h2>
          <div class="border-left border-2 border-primary pl-3">
            <h3 class="text-5">Intermediate Software Developer</h3>
            <p class="mb-2">Agile Bridge / 2021 - Present</p>
            <p class="text-muted">
              At present I am an Intermediate Software Developer at Agile
              Bridge. We primarily use C#, SQL, Angular and Flutter on the
              majority of our projects; we do tend to keep our options open and
              keep up to date with the latest technologies. We work on a variety
              of projects and often get involved in the complete development
              cycle. Previous positions helped to prepare me for my journey with
              <a href="https://www.agilebridge.co.za/">Agile Bridge</a> and they
              provide all the tools to help me grow my career as a developer.
            </p>
            <hr class="my-4" />
            <h3 class="text-5">Software Engineer</h3>
            <p class="mb-2">Korbicom / 2019 - 2021</p>
            <p class="text-muted">
              My software development journey continued as a Software Engineer
              at Korbicom, where we primarily used C#, TypeScript and Angular;
              but kept our options open and strive to keep up to date with all
              the latest technologies. The team was amazing and everyday it felt
              as if I was learning something new. Where BeingIT was the doorway
              to development,
              <a href="https://www.korbicom.com/">Korbicom</a> was an
              introduction to many new technologies and a new way of thinking.
            </p>
            <hr class="my-4" />
            <h3 class="text-5">Software Developer</h3>
            <p class="mb-2">BeingIT / 2015 - 2019</p>
            <p class="text-muted">
              This is where my professional career started in Development. Using
              C# primarily with SQL on a day-to-day basis, it gave me the basis
              to journey into this awesome world of software development.
              Working with amazing developers and a great mentor who pushed me
              to be the best version of myself, I would not be where I am today
              had it not been for my time at
              <a href="https://beingit.co.za/">BeingIT</a>.
            </p>
          </div>
        </div>
      </div>

      <!-- My Experience -->
      <h2 class="text-7 font-weight-600 mb-4 pb-2 mt-5 wow fadeInUp">
        My Skillset
      </h2>
      <div class="row">
        <div class="col-md-6 wow fadeInUp">
          <p class="font-weight-500 text-left mb-2">
            C# <span class="float-right">90%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 90%"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p class="font-weight-500 text-left mb-2">
            HTML / CSS <span class="float-right">95%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 95%"
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p class="font-weight-500 text-left mb-2">
            JavaScript / TypeScript <span class="float-right">90%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 90%"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p class="font-weight-500 text-left mb-2">
            SQL <span class="float-right">95%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 95%"
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="col-md-6 wow fadeInUp" data-wow-delay="0.2s">
          <p class="font-weight-500 text-left mb-2">
            Vue <span class="float-right">70%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 70%"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p class="font-weight-500 text-left mb-2">
            Angular <span class="float-right">85%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 85%"
              aria-valuenow="85"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p class="font-weight-500 text-left mb-2">
            Flutter <span class="float-right">90%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 90%"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p class="font-weight-500 text-left mb-2">
            Python <span class="float-right">45%</span>
          </p>
          <div class="progress progress-sm mb-4">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 45%"
              aria-valuenow="45"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <p class="text-center mt-5 wow fadeInUp">
        <a
          href="resources/Resume.pdf"
          target="_blank"
          class="btn btn-outline-dark shadow-none rounded-0"
          >Download CV</a
        >
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Resume",
};
</script>
