<script>
export default {
  name: "Footer",
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
  },
}
</script>
<template>
  <footer id="footer" class="section bg-dark text-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-center text-lg-left wow fadeInUp">
          <p class="mb-2 mb-lg-0">
            Copyright © {{ getYear() }}
            <a class="btn-link font-weight-600" href="#">Darryn Jacobs</a>. All
            Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>