export default class Project {
  name;
  description;
  createdDate;
  technology;
  url;

  constructor(name, description, createdDate, technology, url) {
    this.name = name;
    this.description = description;
    this.createdDate = new Date(createdDate);
    this.technology = technology;
    this.url = url;
  }

  toDisplayDate() {
    return this.createdDate.toDateString();
  }
}
