<template>
  <section id="about" class="section">
    <div class="container">
      <!-- Heading -->
      <p class="text-center mb-2 wow fadeInUp">
        <span class="bg-primary text-dark px-2">About Me</span>
      </p>
      <h2 class="text-10 font-weight-600 text-center mb-5 wow fadeInUp">
        Get to know me
      </h2>
      <!-- Heading end-->

      <div class="mt-5">
        <div class="row">
          <div class="col-lg-8 text-center text-lg-left wow fadeInUp">
            <h2 class="text-8 font-weight-400 mb-3">
              Hi, I'm
              <span
                class="font-weight-700 border-bottom border-3 border-primary"
                >Darryn Jacobs</span
              >
            </h2>
            <p class="text-5">
              I'm a software developer with a hunger to learn. I enjoy learning
              about new technologies and tackle projects that help to push me as
              a developer. I like to think that I have a
              <strong>get it done</strong> attitude when it comes to my work.
            </p>
          </div>
          <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
            <div class="featured-box style-4">
              <div
                class="featured-box-icon text-25 font-weight-500 bg-primary rounded-circle"
              >
                <span class="wow heartBeat" data-wow-delay="1.3s">7</span>
              </div>
              <h3 class="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span class="font-weight-700">Experiance</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6 col-lg-3 my-2 wow fadeInUp">
          <p class="text-muted font-weight-500 mb-0">Name:</p>
          <p class="text-4 text-dark font-weight-600 mb-0">Darryn Jacobs</p>
        </div>
        <div class="col-6 col-lg-3 my-2 wow fadeInUp" data-wow-delay="0.2s">
          <p class="text-muted font-weight-500 mb-0">Email:</p>
          <p class="text-4 text-dark font-weight-600 mb-0">
            <a class="text-dark font-weight-600 btn-link" href=""
              >info@darrynjacobs.co.za</a
            >
          </p>
        </div>
        <div class="col-6 col-lg-3 my-2 wow fadeInUp" data-wow-delay="0.3s">
          <p class="text-muted font-weight-500 mb-0">Date of birth:</p>
          <p class="text-4 text-dark font-weight-600 mb-0">
            12 December, 1990
          </p>
        </div>
        <div class="col-6 col-lg-3 my-2 wow fadeInUp" data-wow-delay="0.4s">
          <p class="text-muted font-weight-500 mb-0">From:</p>
          <p class="text-4 text-dark font-weight-600 mb-0">
            Cape Town, South Africa.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
};
</script>
