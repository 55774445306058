import { createApi } from "unsplash-js";

export default class ImageService {
  _accessKey;
  _controller;
  _signal;
  _unsplash;

  constructor(accessKey) {
    this._accessKey = accessKey;

    this.init();
  }

  init() {
    this._controller = new AbortController();
    this._signal = this._controller.signal;

    this._unsplash = createApi({
      accessKey: this._accessKey,
    });
  }
  async getRandomHeaderImage() {
    const response = await this._unsplash.photos
      .getRandom(
        {
          count: 1,
          query: "programming",
        },
        { signal: this._signal }
      )
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("Fetch aborted");
        }
      });

    return response.response[0].urls.regular;
  }
  async getRandomCalloutImage() {
    const response = await this._unsplash.photos
      .getRandom(
        {
          count: 1,
          query: "minimal",
        },
        { signal: this._signal }
      )
      .catch((err) => {
        if (err.name === "AbortError") {
          console.log("Fetch aborted");
        }
      });

    return response.response[0].urls.regular;
  }
}
