<template>
  <header id="header" class="sticky-top">
    <!-- Navbar -->
    <nav
      class="primary-menu navbar navbar-expand-none navbar-overlay navbar-text-light bg-transparent border-bottom-0 text-5 font-weight-600"
    >
      <div class="container">
        <!-- Logo -->
        <a class="logo" href="index.html" title="Darryn Jacobs">
          <!-- <img src="images/logo-light.png" alt="Darryn Jacobs" /> -->
        </a>
        <!-- Logo End -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#header-nav"
        >
          <span></span><span></span><span></span>
        </button>
        <div id="header-nav" class="collapse navbar-collapse">
          <div
            class="d-flex flex-column h-100 align-items-center justify-content-center"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link smooth-scroll active" href="#home">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link smooth-scroll" href="#about">About Me</a>
              </li>
              <li class="nav-item">
                <a class="nav-link smooth-scroll" href="#services">What I Do</a>
              </li>
              <li class="nav-item">
                <a class="nav-link smooth-scroll" href="#portfolio"
                  >Some of my work</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link smooth-scroll" href="#resume">Resume</a>
              </li>
              <li class="nav-item">
                <a class="nav-link smooth-scroll" href="#contact"
                  >Lets connect</a
                >
              </li>
            </ul>
            <ul
              class="social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex mt-4"
            >
              <li class="social-icons-medium">
                <a
                  data-toggle="tooltip"
                  href="https://darryn-jacobs.medium.com/"
                  target="_blank"
                  title=""
                  data-original-title="Medium"
                  ><i class="fab fa-medium"></i
                ></a>
              </li>
              <li class="social-icons-linkedin">
                <a
                  data-toggle="tooltip"
                  href="https://www.linkedin.com/in/darryn-jacobs-96759472/"
                  target="_blank"
                  title=""
                  data-original-title="Linkedin"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </li>
              <li class="social-icons-github">
                <a
                  data-toggle="tooltip"
                  href="https://github.com/DarrynJ/"
                  target="_blank"
                  title=""
                  data-original-title="GitHub"
                  ><i class="fab fa-github"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->
  </header></template
>

<script>
export default {
  name: "Header",
};
</script>
