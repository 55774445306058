<template>
  <section id="home">
    <div class="hero-wrap">
      <div class="hero-mask opacity-8 bg-dark"></div>
      <div
        class="hero-bg parallax"
        :style="{ 'background-image': 'url(' + imageUrl + ')' }"
      ></div>
      <div class="hero-content d-flex fullscreen py-5">
        <div class="container my-auto py-4">
          <div class="row">
            <div class="col-lg-12 text-center order-1 order-lg-0">
              <h1 class="text-8 text-white font-weight-400 mb-0 text-uppercase">
                Hi, I'm Darryn and I ...
              </h1>
              <div class="typed-strings">
                <p>Develop</p>
                <p>Write</p>
                <p>Create</p>
              </div>
              <h2
                class="text-23 text-white font-weight-600 text-uppercase mb-0 ml-n1"
              >
                <span class="typed"></span>
              </h2>
              <p class="text-5 text-light">
                based in Cape Town, South Africa.
              </p>
              <a
                href="#portfolio"
                class="btn btn-primary font-weight-600 rounded-0 smooth-scroll mt-3"
                >See what I've been working on</a
              >
            </div>
          </div>
        </div>
        <a href="#about" class="scroll-down-arrow text-light smooth-scroll"
          ><span class="animated"><i class="fas fa-arrow-down"></i></span
        ></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  props: ["imageUrl"],
};
</script>
