<template>
  <section id="portfolio" class="section bg-light">
    <div class="container">
      <!-- Heading -->
      <p class=" text-center mb-2 wow fadeInUp">
        <span class="bg-primary text-dark px-2">Some of my work</span>
      </p>
      <h2 class="text-10 font-weight-600 text-center mb-5 wow fadeInUp">
        Projects I've tackled
      </h2>
      <!-- Heading end-->

      <div class="row">
        <div class="col-lg-12 wow fadeInUp">
          <h2 class="text-7 font-weight-600 mb-4 pb-2">
            Some of my projects on GitHub
          </h2>
          <div class="border-left border-2 border-primary pl-3">
            <div
              v-for="project in projects"
              :key="project.name"
              class="project"
            >
              <h3 class="text-5">
                <a :href="project.url" target="_blank">{{ project.name }}</a>
              </h3>
              <p class="mb-2">
                {{ project.technology }} | Created:
                <small>{{ project.toDisplayDate() }}</small>
              </p>
              <p class="text-muted">
                {{ project.description }}
              </p>
              <hr class="my-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GitHubService from "../services/github.service";
import Project from "../models/Project";

export default {
  name: "Portfolio",
  data() {
    return {
      projects: [],
    };
  },
  beforeMount() {
    this.loadProjects();
  },
  methods: {
    loadProjects() {
      const service = new GitHubService(
        "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"
      );

      service
        .getRepositories()
        .then((response) => response.json())
        .then((data) => {
          data.forEach((prj) => {
            console.log("API proj", prj);
            const project = new Project(
              prj.name,
              prj.description,
              prj.created_at,
              prj.language,
              prj.html_url
            );
            this.projects.push(project);
          });
        });
    },
  },
};
</script>
