export default class GitHubService {
  _accessToken;

  constructor(accessToken) {
    this._accessToken = accessToken;
  }

  async getRepositories() {
    //
    // Another URL for private repos: https://api.github.com/search/repositories?q=user:DarrynJ
    return await fetch("https://api.github.com/users/DarrynJ/repos", {
      headers: {
        Authorization: "Bearer " + this._accessToken,
      },
    });
  }
}
